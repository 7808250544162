<template lang="pug">
  v-menu(v-model="menuShowing" :close-on-content-click="false")
    template(v-slot:activator="{ on }")
      v-icon(v-on="on" small data-test="expand-location-menu").copy-arrow mdi-menu-down
    div.select-menu
      div.select-menu-list
        v-list
          v-list-item(v-if="isShowDeselectButton" @click="deselectAll") Deselect All
          v-list-item(
            v-for="(option, index) in modifiedOptions"
            :key="index"
          )
            v-checkbox(
              hide-details
              v-model="option.checked"
              :label="itemText ? option[itemText] : option"
              data-test="location-item"
            )

      div.sticky-bottom
        v-btn(
          color="primary"
          @click="apply"
          outlined
          data-test="apply-location-filter"
          ) Apply
</template>

<script>
  import Vue from 'vue';

  export default {
    props: {
      options: {
        type: Array,
        required: true,
      },
      itemText: {
        type: String,
        default: ''
      },
      itemValue: {
        type: String,
        default: ''
      },
      selectedOptions: {
        type: Array,
        default: () => [],
      }
    },
    data: () => ({
      menuShowing: false,
      modifiedOptions: []
    }),

    mounted() {
      this.init()
    },

    computed: {
      isShowDeselectButton() {
        if (this.modifiedOptions) {
          return this.modifiedOptions.filter(option => option.checked).length
        }
        return false;
      }
    },

    methods: {
      init() {
        this.modifiedOptions = this._prepareOptions(this.options);
      },
      deselectAll() {
        this.modifiedOptions.forEach(option => option.checked = false);
      },
      apply() {
        const activeValues = this.options.filter(option => option.checked).map(option => option[this.itemValue]);
        this.$emit('change', activeValues);
        this.menuShowing = false;
      },
      _prepareOptions(options) {
        let newOptions = [...options];
        newOptions.forEach(option => {
          if (this.selectedOptions.includes(option[this.itemValue])) {
            Vue.set(option, 'checked', true)
          } else {
            Vue.set(option, 'checked', false)
          }
        });
        return newOptions;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .select-menu {
    max-height: 300px;
    overflow: auto;
    background-color: white;

    .v-input--selection-controls::v-deep{
      margin-top: 0;
    }
  }

  .select-menu .v-input--selection-controls.v-input .v-label::v-deep{
    font-size: 14px;
    font-weight: 500;
  }

  .sticky-bottom{
    background: linear-gradient(to top, rgba(255,255,255) 0% ,rgba(255,255,255,.8) 80%,rgba(255,255,255,.2) 100%);
    padding-bottom: 8px;
    padding-top: 24px;
    position: sticky;
    bottom: 0;
    width: 100%;
    text-align: center;
  }


</style>
